.settings {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings .settingsBlock {
  box-shadow: 1px 1px 6px 1px #454242;
  padding: 10px;
  border-radius: 5px;
  background: white;
}

.settings .colNum {
  width: 30px;
  text-align: right;
}

.settings .setLine {
  display: flex;
  justify-content: space-between;
}

.settings .closeBtn {
  padding: 0 10px 15px;
  text-align: right;
  font-size: 30px;
  cursor: pointer;
}
