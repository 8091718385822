img {
  max-width: 100%;
}
.canvas,
.video {
  max-width: 100%;
}
.btn {
  background: #9be89b;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 20px;
  cursor: pointer;
}
.scanBtn {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.video.hide {
  display: none;
}

.hide {
  display: none;
}
.body {
  margin-bottom: 40px;
}
.footer {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: #eee;
}
.footer .footerBtn {
  display: flex;
  gap: 10px;
}
.footer .settingBtn {
  padding: 10px;
  cursor: pointer;
  background: #cbcaca;
  border-radius: 10px;
  /* gap: 10px; */
  margin-left: 10px;
}
.scanBlock {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scanModal {
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  box-shadow: -1px 1px 2px grey;
  background: white;
}
